import { themeColor } from "../theme/color";

export const StyleOverrides = () => `
*, *::before, *::after {
    box-sizing: border-box;
}
body{
    font-family: 'Inter', sans-serif;
}
.header {
    background:${themeColor.dark};

}
a{
    text-decoration: none;
}
.mainContent{
    border-radius: 10px 10px 0px 0px;
    padding: 25px 25px;
    position: relative; 
    overflow: hidden;
    height: 100%;   
}   
.main-margin{
    margin: -26px;
}
.main-padding{
    padding: 25px 30px;
}
.main{
    padding: 0 30px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
}
.title-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    flex-wrap: wrap;
    gap:15px;
}
.title-padding{
    padding-bottom: 15px;
}
.container{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 4px 5px 0px rgba(112, 144, 176, 0.10);
}
.containerFluid{
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 5px 0px rgba(112, 144, 176, 0.1);
    padding: 20px 30px;
}

.body-box{
    height: 100%;
    overflow: hidden;
    overflow-y: auto;      
}
.bodyContainer{
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: grid;      
}
.d-block{
    display:block;
}
.td{
    font-size: 14px;
}
.cursor{
    cursor: pointer;
}
// Map InfoWindow Override
.gm-style-iw, .gm-style-iw-d {
    padding: 0 !important;
    overflow: hidden !important;
}
.gm-style-iw-tc, .gm-ui-hover-effect {
    display: none !important;
}
.rbc-header + .rbc-header {
    border-left: none !important;
}
.rbc-calendar .rbc-day-bg:first-of-type {
    border-left: 1px solid #E0E0E0;
}
.rbc-calendar .rbc-day-bg:last-child {
    border-right: 1px solid #E0E0E0;
}
.rbc-calendar .rbc-month-view {
    border: none;
}
.rbc-calendar .rbc-header {
    color: #717579;
    text-align: center;
    font-size: 14px;
    font-weight: 510;
    padding-bottom: 10px;
}
.rbc-calendar .rbc-date-cell {
    text-align: center;
}
.rbc-calendar .rbc-date-cell button {
    color: #717579;
    font-size: 16px;
    font-weight: 590;
    line-height: normal;
    letter-spacing: -1.04px;
}
.rbc-calendar .rbc-off-range-bg {
    background: transparent;
}
.rbc-calendar .rbc-off-range button {
    color: #c9c9c9;
}
.rbc-calendar .rbc-date-cell {
    margin-top: 12px;
}
.rbc-month-row:last-child .rbc-row-bg {
    border-bottom: 1px solid #E0E0E0;
}
.rbc-calendar .rbc-event, .rbc-calendar .rbc-event.rbc-selected {
    margin: 0 auto;
    background: #039BE5;
    padding: 0;
}

.rbc-calendar .rbc-month-view .rbc-event, .rbc-calendar .rbc-month-view .rbc-event.rbc-selected {
    margin-left: 4px;
    width: calc(100% - 8px) !important;
}

.rbc-calendar .rbc-event:hover, .rbc-calendar .rbc-event.rbc-selected:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #E7E7E7;
}
.rbc-calendar .rbc-time-view,
.rbc-calendar .rbc-time-header.rbc-overflowing, 
.rbc-calendar .rbc-time-header-content,
.rbc-calendar .rbc-time-header .rbc-header {
    border: none;
}
.rbc-calendar .rbc-time-content {
    border-width: 1px;
}
.rbc-time-gutter .rbc-timeslot-group {
    border: none;
}
.rbc-calendar .rbc-label {
    color: #717579;
    font-weight: 510;
    text-transform: lowercase;
}
.rbc-calendar .rbc-button-link {
    font-size: 16px;
    color: #717579;
    font-weight: 510;
    span {
        width: min-content;
        white-space: break-spaces;
        display: block;
        height: 50px;
    }
}
.rbc-calendar .rbc-show-more {
    color: #000;
    font-size: 9px;
    font-weight: 600;
    width: 90%;
    margin: 5px auto;
    text-align: left;
}
.MuiDayCalendar-monthContainer .MuiPickersDay-root.Mui-selected, 
.MuiDayCalendar-monthContainer .MuiPickersDay-root:focus.Mui-selected,
.MuiDayCalendar-monthContainer .MuiPickersDay-root.Mui-selected:hover {
    border-radius: 10px;
}
.MuiDayCalendar-monthContainer .MuiPickersDay-root:hover {
    border-radius: 10px;
    background: rgba(42, 68, 150, 0.10);
}
.MuiDayCalendar-monthContainer .MuiPickersDay-root.Mui-selected, 
.MuiDayCalendar-monthContainer .MuiPickersDay-root:focus.Mui-selected,
.MuiDayCalendar-monthContainer .MuiPickersDay-root.Mui-selected:hover,
.MuiYearCalendar-root .MuiPickersYear-yearButton.Mui-selected,
.MuiYearCalendar-root .MuiPickersYear-yearButton:focus.Mui-selected,
.MuiYearCalendar-root .MuiPickersYear-yearButton.Mui-selected:hover,
.MuiMultiSectionDigitalClock-root .MuiMenuItem-root.Mui-selected,
.MuiMultiSectionDigitalClock-root .MuiMenuItem-root:focus.Mui-selected,
.MuiMultiSectionDigitalClock-root .MuiMenuItem-root.Mui-selected:hover {
    background: #2A4496;
}
.MuiMultiSectionDigitalClock-root .MuiMultiSectionDigitalClock-root::-webkit-scrollbar {
    width: 6px;
}
.MuiMultiSectionDigitalClock-root .MuiMultiSectionDigitalClock-root::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #AEAEAE;
}
.MuiCheckbox-colorPrimary.Mui-checked {
    color: #2A4496 !important;
}

.select-container {
    .MuiFormLabel-root{
        line-height: 1;
        top: -2px;
        &.MuiInputLabel-shrink{
            line-height: 1.4375em;
            top: 0px;
        }
    }
    .select-white{
        background:#fff;
    }
    .select{
        border-radius: 7px;
        .MuiSelect-select{
            padding: 10px 14px;
        }
        .MuiSelect-icon{
            width: 20px;
            height: 20px;
            top: auto;
        }
        .MuiSelect-select.MuiSelect-outlined {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
        }
    }
}
.input-style {
    .MuiFormLabel-root{
        line-height: 1;
        top: -2px;
        &.MuiInputLabel-shrink{
            line-height: 1.4375em;
            top: 0px;
        }
    }
    &.input-white {
        .MuiInputBase-root {
            background:#fff; 
        }
    }
}
.MuiTabs-flexContainer {
    width: 100%;
}
.convas {
    .konvajs-content {
        margin: 0 auto;
    }
}
.color-input {
    input,
    fieldset {
        padding: 0;
        position: initial;
        border: none;
    }

    input {
        color: #1F1F1F;
        font-size: 10px;
        font-weight: 600;
        width: 45px;
    }

    button {
        width: 45px;
        height: 45px;
        flex-shrink: 0;
        border: 1px solid #000;
    }

    .MuiInputAdornment-positionStart {
        min-height: 50px
    }

    div.MuiInputBase-adornedStart {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
.selectBox {
    .MuiInputBase-input {
        .MuiBox-root {
            .MuiAvatar-root {
                display: none;
            }
        }
    }
}
.rbc-calendar .rbc-time-header-content .rbc-day-bg:first-of-type,
.rbc-calendar .rbc-time-header-content .rbc-day-bg + .rbc-day-bg {
    border: none
}
.rbc-timeslot-group .rbc-time-slot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.rbc-calendar .rbc-event {
    border: none !important;
}

@media (max-width: 1800px) { 
    .rbc-calendar .rbc-month-view .rbc-event, .rbc-calendar .rbc-month-view .rbc-event.rbc-selected {
        max-width: 190px;
    }
}

@media (max-width: 1700px) { 
    .rbc-calendar .rbc-month-view .rbc-event, .rbc-calendar .rbc-month-view .rbc-event.rbc-selected {
        max-width: 180px;
    }
}

@media (max-width: 1600px) { 
    .rbc-calendar .rbc-month-view .rbc-event, .rbc-calendar .rbc-month-view .rbc-event.rbc-selected {
        max-width: 165px;
    }
}

@media (max-width: 1500px) { 
    .rbc-calendar .rbc-month-view .rbc-event, .rbc-calendar .rbc-month-view .rbc-event.rbc-selected {
        max-width: 150px;
    }
}

@media (max-width: 1400px) { 
    .rbc-calendar .rbc-month-view .rbc-event, .rbc-calendar .rbc-month-view .rbc-event.rbc-selected {
        max-width: 135px;
    }
}

@media (max-width: 1299.98px) { 
    .main {
        padding: 0 20px;
        padding-right: 20px;
    }
    .rbc-calendar .rbc-month-view .rbc-event, .rbc-calendar .rbc-month-view .rbc-event.rbc-selected {
        max-width: 120px;
    }
}

@media (max-width: 767.98px) { 
    .mainContent {
        padding: 20px;
    }
    .main {
        padding: 0 20px;
    }
 }
.th-style{
    background-color: #F6F6F6;
    padding-top: 7px;
    padding-bottom: 7px;
    font-weight: 700;
    color: #545454;
}

.th-style-1s {
    background-color: #F6F6F6;
    text-align: left;
    padding: 16px;
    color: #424242;
    font-size: 13px;
    font-weight: 700;
    text-transform: capitalize;
}

.td-bold {
    font-size: 13px;
    color: #000;
    font-weight: 600;
    display: block;
}

.td-text {
    font-size: 13px;
    color: #757575;
    font-weight: 500;
    display: block;

}

.rbc-header.rbc-today, .day .rbc-time-view .rbc-today {
    background-color: transparent;
}

.week .rbc-event, .day .rbc-event {
    padding: 5px;
}

.rbc-time-content .rbc-day-slot .rbc-events-container {
    margin-right: 0;
    left: 3px;
    right: 3px;
}

.MuiPickersPopper-root {
    .MuiList-root {
        border-radius: 0;
    }
    .MuiPickersLayout-contentWrapper, 
    .MuiDialogActions-root  {
        background: rgba(42, 68, 150, 0.05);
    }
    
    .MuiMenuItem-root {
        padding: 0;
        font-size: 15px;
        font-weight: 500;
        color: #1f1f1f;
        background: transparent;
    }
    
    .MuiMenuItem-root:hover,
    .MuiMenuItem-root:focus.Mui-selected, 
    .MuiMenuItem-root.Mui-selected:hover, 
    .MuiMenuItem-root.Mui-selected {
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.05);
    }

    .MuiButtonBase-root {
        font-size: 15px;
        font-weight: 500;
        color: #1f1f1f;
        padding: 0;
    }
}
.checkbox-view {
    .MuiFormControlLabel-root {
       margin-right: 0;
    }
    .MuiButtonBase-root {
       padding: 0px;
    }
}
.todaysSchedule {
    th {
        font-size: 9px;
        background-color: #F6F6F6;
        font-weight: 700;
        color: #424242;
        font-family: 'Inter';
        padding: 8px 10px;
        line-height: 138%;
    }
    td {
        padding: 10px 8.5px;
    }
}

input[type=number] {
    appearance: none; 
    -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none; 
    
}

// Datepicker custom css
.react-datepicker .react-datepicker__current-month {
    color: var(--black, #000);
    font-family: "Inter";
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 18px */
    margin-bottom: 28px;
}

.react-datepicker {
    border-radius: 15px !important;
    border: 1px solid #DDD !important;
    background: #FFF !important;
}

.react-datepicker .react-datepicker__header  {
    background: transparent;
    border: none;
    padding-top: 17px;
    padding-bottom: 10px;
}

.react-datepicker .react-datepicker__navigation-icon--previous::before, 
.react-datepicker .react-datepicker__navigation-icon--next::before {
    border-color: #737373;
    border-style: solid;
    border-width: 2px 2px 0 0;
    height: 6px;    
    top: 6px;
    width: 6px;
}

.react-datepicker .react-datepicker__navigation {
    top: 12px;
}

.react-datepicker .react-datepicker__navigation--previous {
    left: 82px
}

.react-datepicker .react-datepicker__navigation--next {
    right: 82px
}

.react-datepicker .react-datepicker__day-names {
    padding: 0 13px;
}

.react-datepicker .react-datepicker__day-name {
    color: #A6A6A6;
    text-align: right;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 159.5%;
    text-align: center;
    width: 31px;
}

.react-datepicker .react-datepicker__day.react-datepicker__day--outside-month {
    opacity: 0.3
}

.react-datepicker .react-datepicker__day {
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    line-height: 159.5%;
    text-align: center;
    height: 34px;
    width: 31px;
    line-height: 34px;
    border-radius: 10px;
}

.react-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled) {
    color: #010101;
    font-weight: 600;
}

.react-datepicker .react-datepicker__day:not(.react-datepicker__day--disabled):hover {
    border-radius: 10px;
    background: rgba(42, 68, 150, 0.10);
}

.react-datepicker .react-datepicker__day--selected:not(.react-datepicker__day--disabled) {
    border-radius: 10px;
    background: #2A4496;
    color: #FFF;
}

.react-datepicker .react-datepicker__day--selected:hover {
    color: #000;
}

.react-datepicker .react-datepicker__month {
    margin-bottom: 45px;
}

.react-datepicker .react-datepicker__day-name,
.react-datepicker .react-datepicker__day {
    margin: 0 10px;
}

.react-datepicker .react-datepicker__day {
    margin-bottom: 5px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
    fill: #fff !important;
    color: #fff !important;
    stroke: #ddd !important;
}
.rbc-day-slot .rbc-event, .rbc-addons-dnd .rbc-time-view .rbc-event .rbc-addons-dnd-resizable {
    display: flex;
    flex-direction: column-reverse;
    padding: 4px;
}
.rbc-calendar .rbc-time-view .rbc-event:not(.rbc-event-dnd-drag-preview) {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 4px;
    border: 0.5px solid #FFF !important;
}
.react-datepicker-popper {
    z-index: 9
}
.gm-style-iw-chr {
    display: none
}

.day .rbc-event-label {
    display: none
}

.day .rbc-event {
    padding: 0 !important;
}

.week .rbc-day-slot .rbc-event-content {
    overflow: hidden;
    margin-top: 3px;
}
.week .rbc-day-slot .rbc-event {
    flex-flow: initial;
}
.week .rbc-day-slot .rbc-event:hover {
    z-index: 99;
}
.week .rbc-event {
    margin-top: 2px
}
.day .rbc-addons-dnd .rbc-time-view .rbc-event .rbc-addons-dnd-resizable,
.week .rbc-addons-dnd .rbc-time-view .rbc-event .rbc-addons-dnd-resizable {
    padding: 0;
}
.day .rbc-addons-dnd .rbc-time-view .rbc-event {
    overflow: visible;
}
.week .rbc-event:hover,
.week  .rbc-day-slot .rbc-event:hover .rbc-event-content {
    overflow: visible;
    // height: max-content !important;
}
.week  .rbc-day-slot .rbc-event:hover .weekTask30Min {
    display: block;
}
.weekTask30Min {
    display: none;
}
.month .rbc-row-segment {
    margin-bottom: 2px;
}
.day .rbc-day-slot .rbc-event, .rbc-addons-dnd .rbc-time-view .rbc-event .rbc-addons-dnd-resizable {
    padding: 0;
}
.week .rbc-day-slot .rbc-event:hover .rbc-event-content > div {
    display: block;
    background: #039BE5;
    position: relative;
    z-index: 9999;
    border-radius: 5px;
    width: 100%;
}
`;

