import { Avatar, Badge, Box, Divider, Menu, Typography } from "@mui/material";
import React, { useState } from "react";
import { themeColor } from "theme/color";
import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";
import { LiveLeadTag } from "components/Ui/Button/Tage";
import LeadApi from "api/resources/leads";
import { HideInformation } from "components/HasPermission/HasPermission";
import { getSafeValue } from "utils";

function UnreadLeads({ data, handleNavigation, unreadCount }: any) {
  const [anchorElUnread, setAnchorElUnread] = useState(null);
  const open = Boolean(anchorElUnread);
  const handleClose = () => {
    setAnchorElUnread(null);
  };
  const handleClick = (event: any) => {
    setAnchorElUnread(event.currentTarget);
  };

  return (
    <>
      <Badge
        color="error"
        overlap="circular"
        badgeContent={unreadCount}
        sx={{
          "& .MuiBadge-badge": { height: 17, minWidth: 17, fontSize: "11px" },
        }}
      >
        <Avatar onClick={handleClick} sx={{ bgcolor: themeColor.gray }}>
          <img
            src="/assets/images/icons/handshake.svg"
            width={25}
            alt="menu icon"
          />
        </Avatar>
      </Badge>
      <Menu
        anchorEl={anchorElUnread}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            width: '432px',
          },
          sx: {
            '& ul': {
              background: '#fff',
              paddingRight: '10px !important'
            },
            borderRadius: "8px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 16,
              height: 16,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box gap={1} className={styles.flexCenter} sx={{ padding: '12px 19px', marginRight: '12px', borderBottom: '1px solid #F6F6F6' }}>
          <img
            src="/assets/images/icons/handshake.svg"
            alt="handshake"
            style={{ filter: "brightness(0%)" }}
          />
          <Typography variant="title1" color="black" fontWeight={600} fontSize={'17px'}>
            Unread Leads ({unreadCount})
          </Typography>
        </Box>
        <Box className={styles.menuScroll}>
          {data.length > 0 ? (
            data.map((item: any, index: any) => (
              <Box
                className={styles.flexCenter}
                sx={{ cursor: "pointer", "&:hover": { background: "rgba(0, 0, 0, 0.04)" } }}
                key={index}
                p={'7px 19px'}
                onClick={() => handleNavigation(item.id, item.project_id)}
                borderBottom={`${data.length === index + 1 ? "0px" : "1px"} solid #F6F6F6`}
                gap={'23px'}
              >
                <Box>
                  <LiveLeadTag label={item.status} className={item.status} />
                  <Typography
                    fontWeight={600}
                    fontSize={'13px'}
                    whiteSpace="nowrap"
                    variant="title3"
                    display="block"
                  >
                    {item.time}
                  </Typography>
                </Box>
                <Box className={styles.flexPrice}>
                  <Box>
                    <Typography
                      variant="title2"
                      fontWeight={600}
                      fontSize={'15px'}
                      color="#000"
                      lineHeight={'138%'}
                      dangerouslySetInnerHTML={{
                        __html: `${item.service} Window Tinting` || "[service type]",
                      }}
                      className={styles.textOverflow}
                    />
                    <Box display="flex" alignItems="center" gap={1}>
                      {getSafeValue(item, 'project.type', 'Automotive') === 'Automotive' ?
                        <Typography
                          variant="title4"
                          color={'#2E2E2E'}
                          className={styles.textOverflow}
                          fontSize={'11px'}
                          fontWeight={'500'}
                        >
                          ({item.project.car_year || "year"}){" "}
                          {item.project.car_name || "make"}{" "}
                          {item.project.car_model || "model"}{" "}
                        </Typography> :
                        <Typography
                          variant="title4"
                          color={'#2E2E2E'}
                          fontSize={'11px'}
                          fontWeight={'500'}
                          className={styles.textOverflow}
                        >
                          {item.project.building_type || "make"}{" "}
                          {item.project.service_type || "model"}{" "}
                        </Typography>
                      }
                      <Typography>-</Typography>
                      <Typography variant="title4" color={'#2E2E2E'} fontSize={'11px'} fontWeight={'500'}>
                        {item.lead_from}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="title2" fontWeight="600" fontSize={'13px'}>
                    <HideInformation text={`$${item.price ? item.price : '0.00'}`} type={'price'} />
                    
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Box pt={3}>
              <Typography
                textAlign="center"
                fontWeight="bold"
                variant="title2"
                display="block"
              >
                No Unread Leads
              </Typography>
            </Box>
          )}
        </Box>
      </Menu>
    </>
  );
}

export default UnreadLeads;
