import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import ProjectAPI from 'api/resources/project';
import QuickQuoteAPI from "api/resources/quickQuote";
import { HasPermission } from 'components/HasPermission/HasPermission';
import { PrimaryButton, SecondryButton } from "components/Ui/Button/Button";
import { httpService } from "helper/httpService";
import _ from 'lodash';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { RxCaretDown, RxCaretRight } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { useAppSelector } from 'redux/hooks';
import { createQuoteRequest, resetQuoteState, setLoading, setTemporaryJobs, updateActiveStep, updateCompletedSteps, updateQuoteRequest } from "redux/reducers/quickQuoteReducer";
import { getContactRefresh } from 'redux/selectors/contactSelectors';
import { getQuickQuote, selectQuickQuoteInfo } from 'redux/selectors/quickQuoteSelector';
import { convertTimeInUTC, getNextQuarterTime } from 'utils/getCurrentTime';
import { validateData } from "utils/validation/index";
import {
  ApplicationFormSchema,
  AppointmentSchema,
  ContactDetailsSchema,
  ContactFormSchema,
  JobDetailFormSchema
} from "utils/validation/quote";
import styles from "./Footer.module.scss";
import MissingInformationAlert from './MissingInformaionAlert';
import { getSafeValue } from 'utils';

interface FooterProps {
  bookingAppointment: (value: boolean) => void;
  setErrors: (value: any) => void;
}

const Footer = forwardRef((props: FooterProps, ref) => {
  const {
    bookingAppointment,
    setErrors
  } = props;

  const { refresh } = useAppSelector((state) => getContactRefresh(state));
  const { loading, active_step } = useAppSelector((state) => selectQuickQuoteInfo(state));

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [scheduleAppointment, setScheduleAppointment] = useState(false);
  const [isShowMissingInformationAlert, setIsShowMissingInformationAlert] = useState(false);
  const [isConsultationJob, setIsConsultationJob] = useState(false);

  const quickQuote: any = useAppSelector((state) => getQuickQuote(state));
  const userData: any = useAppSelector((state) => state.auth.entities);
  const organizationTimeZone = _.get(userData, 'organisation.timezone', 'UTC')

  const handleOpen = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const validateForm = async (step: any, data: any, ignoreValidation = false) => {
    setErrors({})
    switch (step) {
      case 0: {
        const errors = await validateData(ContactFormSchema, data)
        if (errors?.isValidate === false) {
          setErrors(errors.errors)
          return false
        }
        return true
      }
      case 1: {
        const errors = await validateData(JobDetailFormSchema, data)
        if (errors?.isValidate === false) {
          setErrors(errors.errors)
          return false
        }
        return true
      }
      case 2: {
        const errors = await validateData(ApplicationFormSchema, data)
        if (errors?.isValidate === false) {
          setErrors(errors.errors)
          return false
        }
        return true
      }
      case 3: {
        const errors = await validateData(ContactDetailsSchema, data)
        if (errors?.isValidate === false && ignoreValidation === false) {
          if (Object.keys(errors?.errors).length === 1 && errors?.errors?.contact) {
            setIsShowMissingInformationAlert(true)
          }
          setErrors(errors.errors)
          return false
        }
        return true
      }
      default:
        return true;
    }
  };

  const getFormattedPayload = async (ignoreValidation = false) => {
    const errors = await validateForm(active_step, quickQuote, ignoreValidation)
    if (errors === false) {
      return false
    }
    return quickQuote
  };

  // need to put validation here
  const saveAndExitQuote = async (type = 'Sent') => {
    handleClose()
    const data: any = await getFormattedPayload();
    if (data === false) {
      return false
    }

    data.jobs.forEach((job: any, index: number) => {
      data.jobs[index].status = type;
    });

    let finalData = {
      ...data,
      appointment: undefined
    }
    if (getSafeValue(data, 'appointment.location.save_address_as_contact_address', false) === true) {
      finalData = {
        ...finalData,
        contact: {
          ...finalData.contact,
          address: data.appointment.location
        }
      }
    }
    dispatch(setLoading(true))
    await httpService(() => QuickQuoteAPI.update(quickQuote.id, finalData), updateQuoteRequest, false);
    dispatch(setLoading(false))
    dispatch(resetQuoteState(false));
  };

  useImperativeHandle(ref, () => ({
    saveAndExit() {
      saveAndExitQuote('Quoted');
    }
  }));

  const stepCompletion = () => {
    dispatch(updateCompletedSteps(active_step))
    if (isConsultationJob === true && active_step === 3) {
      setScheduleAppointment(true);
      bookingAppointment(true);
    }
    dispatch(updateActiveStep(active_step + 1))
    dispatch(setLoading(false))
  }

  const handleClick = async (e: any) => {
    setIsConsultationJob(false)
    if (active_step === 4) {
      handleOpen(e);
    } else if (active_step === 0 && !quickQuote.id) {
      const data: any = await getFormattedPayload();
      if (data === false) {
        return false
      }

      dispatch(setLoading(true))
      dispatch(setTemporaryJobs(data.jobs))
      delete data.jobs;
      const res = await httpService(() => QuickQuoteAPI.create(data), createQuoteRequest, false);

      if (res.success) {
        stepCompletion();
      }
      dispatch(setLoading(false))
    } else {
      const ignoreValidation = e === true ? true : false
      const data: any = await getFormattedPayload(ignoreValidation);
      if (data === false) {
        return false
      }

      if (active_step === 3 && (!data?.contact?.phone_number || !data?.contact?.email)) {
        data.contact.phone_number = data.contact.phone_number ? data.contact.phone_number.trim() : data.contact.phone_number;
        data.contact.email = data.contact.email ? data.contact.email.trim() : data.contact.email;
      }

      if (data.jobs[0].type === 'Architectural') {
        if (data.jobs[0].service_type === 'Consultation') {
          setIsConsultationJob(true)
        }
      }

      if (data.jobs && active_step < 2) {
        if (active_step === 0) {
          data.jobs = data.jobs.map((job: any) => (job.type === '' ? { ...job, type: 'Automotive' } : job));
        }
      }
      dispatch(setLoading(true))
      const res = await httpService(() => QuickQuoteAPI.update(quickQuote.id, data), updateQuoteRequest, false);
      if (res.success) {
        stepCompletion();
      }
      dispatch(setLoading(false))
    }
  };

  const handleBackClick = () => {
    let updatedStep = active_step - 1;

    if (scheduleAppointment === true) {
      updatedStep = isConsultationJob === true ? active_step - 1 : active_step;
      setScheduleAppointment(false);
      bookingAppointment(false);
    }

    dispatch(updateActiveStep(updatedStep))
  };

  const { contact, jobs } = quickQuote
  const isMobileJob = getSafeValue(quickQuote, 'jobs[0].mobile_install', false)
  const bookAppointment = async () => {
    const {
      start_time = getNextQuarterTime(organizationTimeZone),
      end_time = moment(getNextQuarterTime(organizationTimeZone)).add(15, 'minutes').format('YYYY-MM-DDTHH:mm'),
      date = moment().format('YYYY-MM-DDTHH:mm'),
      assignees = userData.id,
      allDay = false,
      location = ((jobs[0]?.type === 'Architectural' && jobs[0]?.service_type === 'Consultation') || isMobileJob === true) ? getSafeValue(contact, 'address', {}) : getSafeValue(quickQuote, 'appointment.location', {})
    } = quickQuote.appointment;

    const startDate = convertTimeInUTC(moment(`${moment(date).format('YYYY-MM-DD')} ${moment(start_time).format('hh:mm:ssa')}`, 'YYYY-MM-DD hh:mma'), organizationTimeZone)
    const endDate = convertTimeInUTC(moment(`${moment(date).format('YYYY-MM-DD')} ${moment(end_time).format('hh:mm:ssa')}`, 'YYYY-MM-DD hh:mma'), organizationTimeZone)

    const appointmentData = {
      appointment: {
        allDay,
        startsAt: startDate,
        endsAt: endDate,
        status: 'awaiting',
        type: 'Other',
        is_sharp_time: true,
        location: location,
        assignees: [assignees],
        save_address_as_contact_address: getSafeValue(location, 'save_address_as_contact_address', false),
      }
    };

    const errors = await validateData(AppointmentSchema, appointmentData.appointment)
    if (errors?.isValidate === false) {
      setErrors(errors?.errors)
      return false
    }

    try {
      dispatch(setLoading(true))
      await ProjectAPI.scheduleProjectAppointment(quickQuote.jobs[0].id, appointmentData)
      saveAndExitQuote('Scheduled');
      dispatch(setLoading(false))
      toast.success("Appointment Scheduled");
    } catch (err: any) {
      dispatch(setLoading(false))
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        console.log(err);
      }
    }
  };

  const handleScheduleAppointment = () => {
    setScheduleAppointment(true);
    bookingAppointment(true);
  };

  const handleAlertContinue = () => {
    setIsShowMissingInformationAlert(false)
    handleClick(true)
    if (isConsultationJob === true) {
      handleScheduleAppointment()
    }
  }

  return (
    <div className={styles.btnFooterWrapper}>
      <div className={styles.insideBtnWrapper}>
        {(active_step !== 0) &&
          <Button
            variant="text"
            startIcon={<ArrowBackIcon className={styles.backIcon} />}
            onClick={handleBackClick}
            className={styles.backBtn}
            disabled={loading}
          >
            Back
          </Button>
        }
      </div>

      <div className={styles.insideBtnWrapper}>
        {active_step === 4 ? (
          <>
            {scheduleAppointment ? (<HasPermission permission={['enable_scheduling']}>
              <SecondryButton
                className={styles.scheduleBtn}
                onClick={bookAppointment}
                disabled={loading}>
                Confirm Appointment
              </SecondryButton>
            </HasPermission>
            ) : (
              <>
                <HasPermission permission={['enable_scheduling']}>
                  <SecondryButton
                    className={styles.scheduleBtn}
                    onClick={handleScheduleAppointment}
                    disabled={loading}>
                    Schedule Appointment
                  </SecondryButton>
                </HasPermission>

                <PrimaryButton
                  className={styles.nextBtn}
                  onClick={(e) => handleClick(e)}
                  disabled={loading}
                  endIcon={<RxCaretDown size={20} />}
                >
                  {loading && (
                    <CircularProgress size={20} className={styles.progress} />
                  )}
                  Finish Quote
                </PrimaryButton>
              </>
            )}
          </>
        ) : (
          <PrimaryButton
            className={styles.nextBtn}
            onClick={(e) => handleClick(e)}
            disabled={loading || refresh}
            endIcon={active_step !== 4 && <RxCaretRight size={20} />}
          >
            {(loading || refresh) &&
              <CircularProgress size={20} className={styles.progress} />
            }
            Next
          </PrimaryButton>
        )}

        <Menu
          className={styles.finishBtnMenu}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem className={styles.finishBtn} onClick={() => saveAndExitQuote('Sent')}>
            <Typography>Save & Send Proposal</Typography>
          </MenuItem>
          <MenuItem className={styles.finishBtn} onClick={() => saveAndExitQuote('Quoted')}>
            <Typography>Save & Exit</Typography>
          </MenuItem>
        </Menu>
        {(isShowMissingInformationAlert === true) &&
          <MissingInformationAlert
            onPopupClose={() => setIsShowMissingInformationAlert(false)}
            handleBack={() => setIsShowMissingInformationAlert(false)}
            handleContinue={handleAlertContinue}
          />
        }
      </div>
    </div>
  );
});

export default Footer;
