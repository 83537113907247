import { Box, Typography } from "@mui/material";
import Business from "assets/contact/business.svg";
import ContactIcon from 'assets/icon/icon _call.svg';
import LocationIcon from 'assets/icon/icon_location.svg';
import EnailIcon from 'assets/icon/icon_sms.svg';
import UserIcon from 'assets/icon/icon_user.svg';
import ScraperIcon from 'assets/icon/scraper.svg';
import Behavior from "components/Ui/Tabs/Behavior";
import React from "react";
import uuid4 from "uuid4";
import Styles from "./Overview.module.scss";
import QuoteTotal from "./QuoteTotal";
import { getFullAddress, getSafeValue } from "utils";
import _ from "lodash";

const AutomotiveOverview = ({ job, contact, fromLongQuote = false }: any) => {
  const {
    make,
    model,
    car_type,
    year,
    services,
    mobile_install
  } = job;

  const { address, phone_number, email, first_name, last_name, id, behavior_tag, company } = contact;
  
  let price: any = 0.00;
  services.forEach((service: any) => {
    price = service.discount ? (service.discount_price ? service.discount_price : 0.00) : service.price;
    price = price.toFixed(2)
  });

  return <>
    {fromLongQuote !== true &&
      <Box mt={3} className={Styles.contacInfo}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="subTitle1" >Contact Information</Typography>
          <Behavior id={id} value={behavior_tag} />
        </Box>
        <Box py={3} className={Styles.flexWrapper}>
          <Box className={Styles.contactInfoWrapper}>
            <img src={UserIcon} alt='' />
            <Typography variant="title2" className={Styles.contactDetail}>{first_name} {last_name}</Typography>
          </Box>
          {company &&
            <Box className={Styles.contactInfoWrapper}>
              <img src={Business} alt='' />
              <Typography variant="title2" className={Styles.contactDetail}>{company}</Typography>
            </Box>
          }

          <Box className={Styles.contactInfoWrapper}>
            <img src={ContactIcon} alt='' />
            <Typography variant="title2" className={Styles.contactDetail}>{phone_number !== null ? phone_number : '-'}</Typography>
          </Box>

          <Box className={Styles.contactInfoWrapper}>
            <img src={EnailIcon} alt='' />
            <Typography variant="title2" className={Styles.contactDetail}>{email !== null ? email : '-'}</Typography>
          </Box>
        </Box>

        {(getSafeValue(contact, 'address', false) && !_.isEmpty(getSafeValue(contact, 'address', false))) &&
          <Box className={Styles.flexWrapper}>
            <Box className={Styles.contactInfoWrapper}>
              <img src={LocationIcon} alt='' />
              <Typography variant="title2" className={Styles.contactDetail}>{getFullAddress(address)}</Typography>
            </Box>
          </Box>
        }
      </Box>}

    <Box className={Styles.jobDetail}>
      <Box className={fromLongQuote === true ? Styles.jobDetailHeaderLongQuote : Styles.jobDetailHeader}>
        <Box className={Styles.jobTitle}>
          <Typography variant="subtitle1" className={Styles.subTitle1}>({year}) {make} {model}</Typography>
          <Typography variant="subtitle2" className={Styles.subTitle2}>{car_type}</Typography>
        </Box>
        {fromLongQuote === true && <Typography variant="subtitle2" className={Styles.titleJobId}>Job #{job.id}</Typography>}
      </Box>

      <Box className={Styles.jobServiceType}>
        {services.map((service: any) => (
          <React.Fragment key={uuid4()}>
            <Typography variant="subtitle1" fontWeight={'500'} className={Styles.serviceType}>{service.type === 'Other' ? 'Other Service' : `${mobile_install === true ? 'Mobile' : ''} ${service.type}`}</Typography>
            <Box key={`service_${service.id}`}>
              {service?.applications.map((application: any) => (
                <Box key={`project_tint_applications_${application.id}`} className={Styles.jobServiceInfo}>
                  <Typography variant="subtitle1" className={Styles.serviceName}>{application.area} {application.film_removal === true && <img width='14px' src={ScraperIcon} alt="scraper" />}</Typography>
                  {(application?.films[0]?.name) && <Typography variant="subtitle2" className={Styles.serviceFilm}>{application?.films[0]?.name}</Typography>}
                </Box>
              ))}
            </Box>
          </React.Fragment>
        ))}

        <QuoteTotal
          job={job}
          fromLongQuote={fromLongQuote}
        />
      </Box>
    </Box>
  </>
};

export default AutomotiveOverview;